import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import "./dialogStyles.css";

export const LogoutDialog = (props) => {
  const { open, setOpen, logoutDialogHandler } = props;

  return (
    <>
      <Dialog className="logout-dialog" open={open} onClose={logoutDialogHandler} aria-labelledby="logout-dialog-title" aria-describedby="logout-dialog-description">
        <DialogContent>
          <div id="logout-dialog-title" className="header-content">
            Session has been timed out
          </div>
          <hr />
          <div id="alert-dialog-description" className="description-content">
            Your session has been logged out due to inactivity. Click on <b>Login &nbsp;</b> below to continue to the Login page.
          </div>
          <hr />
        </DialogContent>
        <DialogActions>
          <div onClick={(event, reason) => logoutDialogHandler(event, reason, false)} className="continue-session" style={{ width: "81px" }}>
            Login
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
