import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import jwt_decode from 'jwt-decode';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '250px',
    maxHeight: '250px',
    // backgroundColor: "#ffffff",
    // marginTop:"7px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 230,
    width: 250,
    // height: 32,
    borderRadius:"8px",
    backgroundColor: "#ffffff",
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px!important',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled' : {
      backgroundColor: "#DFE2DE",
      opacity:"50%"
    },
    '& .MuiSelect-select:focus' : {
      backgroundColor: "#ffffff"
    }
  },
  InputLabel: {
    '& .MuiList-padding': {
      padding: '0px!important',
    },
    "& .MuiInputBase-input.Mui-disabled": {
      backgroundColor: "#d7d9d6"
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  icon: {
    fill: '#21DA8C',
    fontSize: '28px',
    '&.MuiSelect-icon.Mui-disabled':{
      fill:"#aca9a7"
    }
  },
  selected: {
    borderRadius: '6px',
    height:"32px !important",
    backgroundColor: "#ffffff",
    '&.MuiSelect-select:focus':{
      backgroundColor: "#ffffff !important"
    }
  },
  listItem: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#8A8A8A',
    fontFamily: 'Inter',
    '&.MuiSelect-select:focus':{
      backgroundColor: "#ffffff !important"
    }
  },
  outlinedInput: {
    '&$focused $notchedOutline': {
      border: '1px solid #4A90E2 !important',
    },
  },
}));

function Dropdown({
  dropdownLabel,
  setIsActivities,
  setIsOtherCategory,
  parentDropDown,
  isDisabled,
  isActivities,
  setIsSourceSelected,
  setIsActivityTypeSelected,
  body,
  screenVal,
  setTypeScreenVal,
  setSourceScreenVal,
  setSubAttrVal,
  setAttrVal,
  setAttrType,
  CATEGORIES,
  subAttrObj,
  label,
  setRows,
  attrVal,
  subAttrVal,
  typeScreenVal,
  isOtherCategory,
  setReset,
  reset
}) {
  const [attribute, setAttribute] = React.useState('');
  // const [subAttrObj, setSubAttrObj] = React.useState({});
  const classes = useStyles();

  let userData = {};

  if (localStorage.getItem('b2bToken') != undefined) {
    try {
      userData = jwt_decode(localStorage.getItem('b2bToken'));
    } catch (error) {
      console.error(error);
    }
  }


  const handleValueChange = async (event) => {
    try {
      const {
        target: { value },
      } = event;

      setAttribute(value);
      if(setRows)
        setRows([])
      if (value === 'Activities' && parentDropDown) {
        setAttrType(value);
        setSubAttrVal(false);
        setAttrVal(false);
        setIsActivities(true);
        setSourceScreenVal(false);
      } else if (parentDropDown) {
        setIsActivities(false);
        setIsOtherCategory(true);
        setAttrVal(false);
        setSourceScreenVal(false);
        setAttrType(value);
        setSubAttrVal(false);
      } else {
        if (body?.Category === 'Activities') {
          if (screenVal === 'Source') {
            setSourceScreenVal(value);
            setIsSourceSelected(true);
            setTypeScreenVal(false);
            setSubAttrVal(false);
            setReset(true);
          } else if (screenVal === 'Type') {
            setTypeScreenVal(value);
            setSubAttrVal(false)
            setIsActivityTypeSelected(true);
            setReset(true);
          } else if (screenVal === 'SubAttributes') {
            console.log("subAttrObj->",subAttrObj);
            setSubAttrVal(subAttrObj?.[value]);
          }
        } else if (screenVal === 'Attributes') {
          setAttrVal(value);
        }
      }
    } catch (error) {
      console.error('Error in handle value change', error);
    }
  };


  return (
    <>
    <div>
      <span>
      <Typography  className="page-heading" style={{ 
        fontFamily: "Inter",
        fontWeight: '500',
        fontSize: '12px',
        marginLeft:"13px",
        lineHeight: '14.52px',
        color:"#000000DE",
        marginBottom:"5px"
      }}>{label}</Typography>
      </span>
      {/* <span style={{marginTop:"8px"}}> */}
      <FormControl size="small" variant="outlined" className={classes.formControl} 
      sx={{
        width: '250px',
        height: '32px',
      }}
      disabled={!isDisabled}>
      {/* <InputLabel id="attribute-setup-label" className={classes.InputLabel}>
        {dropdownLabel}
      </InputLabel> */}
      {
        isOtherCategory && (attribute === "" || (!attrVal && !parentDropDown))
        ?
        <div style={{ position: "absolute", transform: "translate(16px, 8px)", zIndex:1}}>{dropdownLabel}</div>
        :
        screenVal !== 'SubAttributes' ? 
        (attribute === "" || ((!typeScreenVal && screenVal === 'Type' && setAttribute('')))) 
        
        && <div style={{ position: "absolute", transform: "translate(16px, 8px)", zIndex:1, color: !isDisabled ? '#aca9a7' : "#000000" , opacity: !isDisabled ? '50%' : "100%"}}>{dropdownLabel}</div>
        :
        (attribute === "" || ((subAttrVal === false) && setAttribute(''))) 
        // || console.log("subAttrVal inside", subAttrVal)
        && <div style={{ position: "absolute", transform: "translate(16px, 8px)", zIndex:1, color: !isDisabled ? '#aca9a7' : "#000000" , opacity: !isDisabled ? '50%' : "100%"}}>{dropdownLabel}</div>
       
      }
      
      <Select
        labelId="attribute-setup-label"
        id="attribute-setup"
        value={attribute}
        sx={{ width: '100px !important', padding: '10px !important', borderRadius: '5px' }}
        onChange={handleValueChange}
        // label={dropdownLabel}
        inputProps={{
          classes: {
            icon: classes.icon,
            root: classes.root,
          },
        }}
        IconComponent={ExpandMoreOutlinedIcon}
        MenuProps={{
          classes: {
            paper: classes.paper,
          },
          PaperProps: {
            style: {
              borderRadius: '6px',
              top:"174px"
            },
          },
          getContentAnchorEl: null,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        }}
      >
        {CATEGORIES?.map((element) => (
              <MenuItem key={element} className={classes.listItem} classes={{ selected: classes.selected }} value={element}>
                {isActivities ? (
                  <span style={{ marginRight: '12px' }}>
                    <img
                      width="20"
                      height="20"
                      src={`${window._env_.INTEGRATION_SETUP}/logos/${element == null ? '' : element.toLowerCase()}.png`}
                      alt={`${element} logo`}
                    />
                  </span>
                ) : (
                  ''
                )}
                {element}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
      {/* </span> */}
    </div>
    
    
    </>
    
  );
}

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
};

Dropdown.defaultProps = {
  onChange: () => {},
};

export default Dropdown;
