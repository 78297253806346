import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import './tablePagination.css';

export default function TablePagination(props) {
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPages] = useState([1,2,3,4,5,6]);
    const [pageSize, setPageSize] = useState(15);
    const [selectedPage, setSelectedPage] = useState(1);
    const [arr, setArr] = useState([]);

    useEffect(()=>{
        console.log("totalpages",props.rows.length,pageSize);
        console.log("page 16",page);
        const totalPages = props.rows.length/pageSize;
        const pageArr = [];
        for(let i=0 ; i<totalPages ;i++){
            pageArr.push(i+1);
            if(i > 6) break;
        }
        console.log("pageArr->",pageArr);
        console.log("totalPage->",totalPage);
        setTotalPages(pageArr);

    },[pageSize]);

    useEffect(() => {
        console.log('coming inside useEffect line 29',totalPage,arr);
        console.log("page 29",page);
        
        if (parseInt(page) <= 6) {
            console.log('useEffect running');
            let newArr = [1, 2, 3, 4, 5, 6]
            if(newArr.includes(parseInt(totalPage))){
                let indexOfLast = newArr.indexOf(parseInt(totalPage))
                newArr.length = indexOfLast + 1
            }

            console.log("newArr->",newArr);
            setArr(totalPage);
        }
    }, [totalPage]);
    

    const handleClick = (event) => {
        
        if ((event.target.value == arr[arr.length - 1] && arr[arr.length - 1] != totalPage)||(event.target.value == arr[arr.length - 2] && arr[arr.length - 2] != totalPage)) {
            if(!(arr[arr.length - 1] < 3)){
                let newArr = arr.map((item) => {
                    return (item + 2);
                });
                if(newArr.includes(parseInt(totalPage))){
                    let indexOfLast = newArr.indexOf(parseInt(totalPage))
                    newArr.length = indexOfLast + 1
                }
                setArr(newArr);
            }
        }
        if (event.target.value == arr[0] && event.target.value != 1 && !(arr[arr.length - 1] < 6)) {
            let newArr = arr.map((item) => {
                return (item - 2);
            })
            setArr(newArr);
        }
        console.log("event.target.value")
        setSelectedPage((prevState) => event.target.value);
        props.setStartPage(parseInt(event.target.value) * parseInt(pageSize) - parseInt(pageSize));
        props.setEndPage(parseInt(event.target.value) * parseInt(pageSize));
        setPage(event.target.value * 1);
    }

    function handlePageSizeChange(event) {
        console.log("pageSize->",event.target.value);
        setPageSize(event.target.value);
        props.setStartPage(0);
        props.setEndPage(parseInt(event.target.value));
    }

    let backButtonDisabled = page - 1 < 1;
    let nextButtonDisabled = page + 1 > props.rows.length;

    const handlerNextPage = (event) => {
        console.log("page 84",page);
        console.log(event.target.value, arr[arr.length - 1],selectedPage, totalPage,"from handleNextPage")
        if (selectedPage == arr[arr.length - 1] && arr[arr.length - 1] != Math.ceil(props.rows.length/pageSize)) {
            console.log("condition is true");
            let newArr = arr.map((item) => {
                return (item + 1);
            })
            setArr((prevArr) => newArr);
        }
        setSelectedPage(page + 1);
        props.setStartPage(parseInt(page + 1) * parseInt(pageSize) - parseInt(pageSize));
        props.setEndPage(parseInt(page + 1) * parseInt(pageSize));
        setPage(page + 1);
    }

    const handlerPrevPage = (event) => {
        console.log("page 100",page);
        if (selectedPage == arr[0] && event.target.value != 1) {
            let newArr = arr.map((item) => {
                return (item - 1);
            })
            setArr((prevArr) => newArr);
        }
        setSelectedPage(page - 1);
        props.setStartPage(parseInt(page - 1) * parseInt(pageSize) - parseInt(pageSize));
        props.setEndPage(parseInt(page - 1) * parseInt(pageSize));
        setPage(page - 1);
    }
    return (
        <>
            <div style={{marginLeft:"48px", marginTop:"10px", width:"1150px", display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography className="page-heading" style={{ 
                    fontFamily: "Inter",
                    fontSize: "11px",
                    fontWeight: "500",
                    lineHeight: "13.31px",
                    textAlign: "left",
                }}>
                    Showing rows {props.startPage + 1} - { page + 1 > Math.ceil(props.rows.length/pageSize) ? props.endPage-1 : props.endPage } of {props.rows.length}
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className="page-heading" style={{ 
                        fontFamily: "Inter",
                        fontSize: "11px",
                        fontWeight: "500",
                        lineHeight: "13.31px",
                        textAlign: "right",
                        marginRight: '8px'
                    }}>
                        Rows per page
                    </Typography>
                    <select name="2" style={{width:"48px", height:"24px", borderRadius:"8px", marginLeft:"8px", marginRight:"8px"}}
                    onChange={handlePageSizeChange}>
                        <option value="15">15</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                        <option value="300">300</option>
                        <option value="1000">1000</option>
                    </select>
                    <button
                        className={`rgt-footer-pagination-button${backButtonDisabled ? ' rgt-disabled-button' : ''}`}
                        disabled={page - 1 < 1}
                        onClick={handlerPrevPage}
                    >
                        <Typography className="page-heading" style={{ 
                        fontFamily: "Inter",
                        fontSize: "11px",
                        fontWeight: "500",
                        lineHeight: "13.31px",
                        textAlign: "right",
                        marginLeft: '8px',
                        marginRight: '8px',
                        opacity: page - 1 < 1 === true ? '50%' : "100%",
                    }}
                    
                    >
                        Prev
                    </Typography>
                    </button>
                    
                    {arr.length ? <div className='rgt-footer-pagination-input-container' style={{display:"flex", gap:"8px"}}>
                {
                    arr.map((item) => {
                        return <button
                            key={item}
                            style={item == selectedPage ? { textDecorationThickness: '2px', textUnderlineOffset: "2px", fontWeight: `800` } : { paddingBottom: '5px' }}
                            className='rgt-footer-page-input'
                            type='number'
                            value={item}
                            onClick={handleClick}
                        >
                            {item}
                        </button>
                    })
                }
            </div> : <Skeleton variant="rectangular" width={"100px"} height={"1em"} />
            }
                <button
                    className={`rgt-footer-pagination-button${nextButtonDisabled ? ' rgt-disabled-button' : ''}`}
                    disabled={page + 1 > Math.ceil(props.rows.length/pageSize)}
                    onClick={handlerNextPage}
                ><Typography className="page-heading" style={{ 
                    fontFamily: "Inter",
                    fontSize: "11px",
                    fontWeight: "500",
                    lineHeight: "13.31px",
                    textAlign: "right",
                    marginLeft: '8px',
                    opacity: page + 1 > Math.ceil(props.rows.length/pageSize) === true ? '50%' : "100%",
                }}>
                    Next
                </Typography></button>
                    
                </div>
            </div>
        </>
    );
}
